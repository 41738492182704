@import '../../../styles/colors';

button {
    border: 2px solid $burntOrange;
    background-color: $splashDark;
    color: $burntOrange;
    font-family: 'Lato', sans-serif;
    height: 40px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: bolder;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
    min-width: 150px;
    width: 100%;
    text-transform: uppercase;

  &:focus,
  &:active,
  &:hover {
    background-color: $contactBackground;
    color: $teal;
    border-color: $teal;
    outline: none;
    box-shadow: none;
  }
}