@import './colors';
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900,900i&display=swap');

body {
    font-family: 'Lato', sans-serif;
    margin: 0;
}

.highlight {
    color: $highlight !important;
}
