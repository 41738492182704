@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900,900i&display=swap);

.homeContainer {
  top: 55px;
  position: absolute;
  background-color: #2A2E38;
  background: -webkit-linear-gradient(top, #2A2E38, #2A2E38 30%, #E6E6E6 30%, #E6E6E6);
  background-position: 0 -200px;
  width: 100%; }
  .homeContainer:not(.mobile) .projectWrapper {
    display: flex;
    margin-top: 50px; }
    .homeContainer:not(.mobile) .projectWrapper > * {
      width: 50%; }
    .homeContainer:not(.mobile) .projectWrapper.even {
      margin-left: 50px; }
    .homeContainer:not(.mobile) .projectWrapper.odd {
      margin-right: 50px;
      flex-direction: row-reverse; }
  .homeContainer .mobile .projectWrapper {
    margin-bottom: 25px; }
  .homeContainer .projectWrapper {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); }
  .homeContainer .contactWrapper {
    min-height: 50px; }


.navContainer {
  color: #E4DCD5;
  background-color: #2A2E38;
  display: flex;
  justify-content: space-between;
  height: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  top: 0;
  width: 100%; }
  .navContainer .navLinks {
    display: flex;
    flex-direction: row-reverse; }
    .navContainer .navLinks span {
      font-weight: 700;
      cursor: pointer; }
      .navContainer .navLinks span:hover {
        font-weight: 800;
        color: #3AC1C4 !important; }
      .navContainer .navLinks span:last-of-type {
        color: #E3990F; }
    .navContainer .navLinks:not(.mobile) span {
      margin-right: 20px; }
      .navContainer .navLinks:not(.mobile) span:first-of-type {
        margin-right: 50px; }
    .navContainer .navLinks.mobile {
      width: 100%;
      justify-content: space-evenly; }
  .navContainer .socialLinkContainer {
    margin-left: 50px; }

.footerContainer {
  background-color: #e2dad3;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  box-sizing: border-box; }
  .footerContainer .info {
    display: flex;
    flex-direction: column; }
    .footerContainer .info :first-child {
      margin-bottom: 5px; }
  .footerContainer .socialLinkContainer {
    height: 40px; }

.splashWrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh; }
  .splashWrapper .card {
    text-align: center;
    margin-top: -110px;
    font-size: .9em; }
    .splashWrapper .card button {
      width: 150px; }
      .splashWrapper .card button:first-of-type {
        margin-right: 10px; }

@media (max-width: 600px) {
  .splashWrapper .card h2 {
    font-size: 2.2em; }
  .splashWrapper .card button {
    font-size: 1.5em;
    width: 80%;
    height: 70px;
    margin-bottom: 10px; }
    .splashWrapper .card button:first-of-type {
      margin-right: unset; } }

.logoContainer {
  cursor: pointer;
  height: 100%; }
  .logoContainer img {
    height: 100%; }

.socialLinkContainer {
  display: flex;
  height: 100%; }
  .socialLinkContainer .logoContainer {
    margin-right: 10px; }

.contactFormContainer {
  background-color: #2A2E38;
  color: #E6E6E6;
  height: 600px;
  margin-top: -200px;
  padding-top: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .contactFormContainer .formWrapper {
    width: 65%; }

button {
  border: 2px solid #E3990F;
  background-color: #2A2E38;
  color: #E3990F;
  font-family: 'Lato', sans-serif;
  height: 40px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bolder;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  min-width: 150px;
  width: 100%;
  text-transform: uppercase; }
  button:focus, button:active, button:hover {
    background-color: #4C4D52;
    color: #3AC1C4;
    border-color: #3AC1C4;
    outline: none;
    box-shadow: none; }

.inputContainer {
  position: relative;
  height: 50px;
  margin: 5px auto 0; }
  .inputContainer input {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #4C4D52;
    width: 100%;
    min-width: 150px;
    height: 50px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 1px;
    border: none;
    color: #E3990F;
    font-size: 14px; }
    .inputContainer input::-webkit-input-placeholder {
      color: #E3990F; }
    .inputContainer input::-moz-placeholder {
      color: #E3990F; }
    .inputContainer input:-ms-input-placeholder {
      color: #E3990F; }
    .inputContainer input::-ms-input-placeholder {
      color: #E3990F; }
    .inputContainer input::placeholder {
      color: #E3990F; }
    .inputContainer input:focus {
      outline-style: none;
      box-shadow: none;
      border: solid 1px #3AC1C4;
      color: #3AC1C4; }
  .inputContainer .error-text {
    display: none; }

.inputContainer.has-error input {
  border-color: #C75C61; }

.inputContainer.has-error .error-text {
  display: inherit;
  font-size: .7em;
  color: #C75C61;
  margin: 0;
  margin-top: 5px; }

.textAreaContainer {
  position: relative;
  height: 250px;
  margin: 5px auto 5px; }
  .textAreaContainer textarea {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #4C4D52;
    width: 100%;
    min-width: 150px;
    height: 250px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 1px;
    border: none;
    color: #E3990F;
    font-size: 14px; }
    .textAreaContainer textarea::-webkit-input-placeholder {
      color: #E3990F; }
    .textAreaContainer textarea::-moz-placeholder {
      color: #E3990F; }
    .textAreaContainer textarea:-ms-input-placeholder {
      color: #E3990F; }
    .textAreaContainer textarea::-ms-input-placeholder {
      color: #E3990F; }
    .textAreaContainer textarea::placeholder {
      color: #E3990F; }
    .textAreaContainer textarea:focus {
      outline-style: none;
      box-shadow: none;
      border: solid 1px #3AC1C4;
      color: #3AC1C4; }
  .textAreaContainer .error-text {
    display: none; }

.textAreaContainer.has-error textarea {
  border-color: #C75C61; }

.textAreaContainer.has-error .error-text {
  display: inherit;
  font-size: .7em;
  color: #C75C61;
  margin: 0;
  margin-top: 5px; }

.projectInfoContainer {
  background-color: #CFC7C1;
  display: flex;
  flex-direction: column; }
  .projectInfoContainer img {
    max-height: 50px;
    width: auto;
    max-width: 200px;
    margin: 30px;
    margin-bottom: 0;
    align-self: flex-start; }
  .projectInfoContainer p {
    padding: 0 30px;
    margin-bottom: 5px; }
  .projectInfoContainer .demoLinks {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-transform: uppercase;
    margin: 30px;
    margin-top: 20px; }
    .projectInfoContainer .demoLinks span:hover {
      font-weight: 900; }

.even .projectInfoContainer img {
  align-self: flex-start; }

.odd .projectInfoContainer img {
  align-self: flex-end; }

.projectScreenShotContainer {
  align-items: center;
  background-color: #e2dad3;
  display: flex;
  justify-content: center; }

.projectScreenShotMobileContainer {
  align-items: center;
  background-color: #e2dad3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px; }
  .projectScreenShotMobileContainer .screenShotWrapper,
  .projectScreenShotMobileContainer .demosWrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly; }
  .projectScreenShotMobileContainer .demosWrapper {
    font-size: 1.2em; }

.projectInfoMobileContainer {
  background-color: #CFC7C1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px; }
  .projectInfoMobileContainer img {
    max-height: 50px;
    width: auto;
    max-width: 200px;
    margin: 30px;
    margin-bottom: 0;
    align-self: flex-start; }
  .projectInfoMobileContainer p {
    padding: 0 30px;
    margin-bottom: 5px; }
  .projectInfoMobileContainer .demoLinks {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-transform: uppercase;
    margin: 30px;
    margin-top: 20px; }
    .projectInfoMobileContainer .demoLinks span:hover {
      font-weight: 900; }

.contactFormmobileContainer {
  background-color: #2A2E38;
  color: #E6E6E6;
  padding-bottom: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .contactFormmobileContainer .formWrapper {
    width: 90%; }

.footerMobileContainer {
  background-color: #e2dad3;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  font-size: 1.4em; }
  .footerMobileContainer .info {
    display: flex;
    flex-direction: column; }
    .footerMobileContainer .info :first-child {
      margin-bottom: 5px; }
  .footerMobileContainer .socialLinkContainer {
    height: 60px;
    width: 80%;
    justify-content: space-evenly; }

.splashScreenContainer {
  background-color: #2A2E38;
  background-image: -webkit-linear-gradient(-55deg, #2B2E37 60%, #E2DAD3 50%);
  background-position: 0px 50px;
  background-repeat: no-repeat;
  color: #E4DCD5; }

body {
  font-family: 'Lato', sans-serif;
  margin: 0; }

.highlight {
  color: #C65C61 !important; }

