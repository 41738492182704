@import '../../styles/colors';

.navContainer {
    color: $eggshell;
    background-color: $splashDark;
    display: flex;
    justify-content: space-between;
    height: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    .navLinks {
        display: flex;
        flex-direction: row-reverse;
        span {
            font-weight: 700;
            cursor: pointer;
            &:hover {
                font-weight: 800;
                color: $teal !important;
            }
            &:last-of-type {
                color: $burntOrange;
            }
        }
        &:not(.mobile) span {
            margin-right: 20px;
            &:first-of-type {
                margin-right: 50px;
            }
        }
        &.mobile {
            width: 100%;
            justify-content: space-evenly;
        }
    }
    .socialLinkContainer {
        margin-left: 50px;
    }
}