$main-dark: #020923;
$main-light: #FFFEFD;
$slate: #252934;
$coral: #C75C61;
$teal: #3AC1C4;
$mint: #B7E7E8;
$golden: #ECDA9F;
$steel: #AEC1CC;
$mithril:  #163E5A;
$active: #347779;
$green: #5ACD90;
$dustyGray: #9C9C9C;
$greyBlue: #e6ecef;
$seafoam: #83cccb;

$charcoal: #22252A;
$slate: #292F38;
$white: #E6E6E6;
$navy: #011936;

$splashDark: #2A2E38;
$splashLight: #E4DAD2;

$burntOrange: #E3990F;
$highlight: #C65C61;
$eggshell: #E4DCD5;
$background: #2B2E37;

$projectLight: #e2dad3;
$projectDark: #CFC7C1;
$projectFont: #45474F;

$contactBackground: #4C4D52;
$contactFooterBG: #E2DAD3;

// $gradient: linear-gradient(-90deg, #011936 0%, #0E304C 13%, #255A74 35%, #6A5B6C 63%, #C75D62 100%;