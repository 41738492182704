@import '../../../styles/colors';

.footerMobileContainer {
    background-color: $projectLight;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 1.4em;
    .info {
        display: flex;
        flex-direction: column;
        :first-child {
            margin-bottom: 5px;
        }
    }
    .socialLinkContainer {
        height: 60px;
        width: 80%;
        justify-content: space-evenly;
    }
}