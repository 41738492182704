@import '../../styles/colors';

.splashWrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100vh;

    .card {
        text-align: center;
        margin-top: -110px;
        font-size: .9em;

        button {
            width: 150px;
            &:first-of-type {
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 600px) {
    .splashWrapper {
        .card {
            h2 {
                font-size: 2.2em;
            }
            button {
                font-size: 1.5em;
                width: 80%;
                height: 70px;
                margin-bottom: 10px;
                &:first-of-type {
                    margin-right: unset;
                }
            }
        }
    }
}